import React, {useEffect} from 'react'



const Totem = () => {
    useEffect(() => {
        document.title="ESP Totem"
    },[])

    return (
        <div>
            Page Totem
        </div>
    )
}

export default Totem
