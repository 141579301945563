import React, {useState} from 'react'
import ImgPLL from './ImgPLL'
import './MiniPLL.css'

const Faces = props => {

    const [color, setColor] = useState("#757575")
    const [border, setBorder] = useState("1px solid #757575")
    const [fontweights, setFontweights] = useState("unset")
    const [fontweightd, setFontweightd] = useState("unset")
    const [imgpll, setImgpll]= useState("SF")


    const focusin = () => {
        setColor("#000")
        setBorder("2px solid #000000")
        
    }

    const Focusout = () => {
        setColor("#757575")
        setBorder("1px solid #757575")
        
    }

    const changes = (e) => {

        if(e.target.checked) {
            setFontweights("bold")
            setFontweightd("unset")
        }else {
            setFontweights("unset")
        }

        setImgpll(e.target.value)
    }

    const changed = (e) => {
        if(e.target.checked) {
            setFontweightd("bold")
            setFontweights("unset")
        }else {
            setFontweightd("unset")
        }
        setImgpll(e.target.value)
    }



    return (
        <div>
            <fieldset id="NfacesField" style={{border:border}}>
                <legend style={{color:color}} className="legend">Eclairage :</legend>
                <div id="Nfaces">
                    <div id="Nfaces2">
                        <div className="Nfaces3">
                            <input type="radio" id="simpleface"  name="face" value="SF" onClick={(e) => props.setFaces(e.target.value)}  onChange={changes} onFocus={focusin} onBlur={Focusout}/>
                            <label htmlFor="simpleface" className="radio" style={{fontWeight:fontweights}}>Simple</label>
                        </div>
                        <div className="Nfaces3">
                            <input type="radio" id="doubleface"  name="face" value="DF" onClick={(e) => props.setFaces(e.target.value)}  onChange={changed} onFocus={focusin} onBlur={Focusout}/> 
                            <label htmlFor="doubleface" className="radio" style={{fontWeight:fontweightd}}>Double</label>
                        </div>    
                    </div>
                    <ImgPLL eclarage={imgpll}/>
                </div>
            </fieldset>
        </div>
    )
}

export default Faces
