import React, {useState} from 'react'
import Modal from 'react-modal'

import MiniPLL from './IMG/Mini-PLL.jpg'
import MiniPLLD from './IMG/Mini-PLLD.jpg'
import MiniPLLB from './IMG/MiniPLLB.jpg'
import MiniPLLDB from './IMG/MiniPLLDB.jpg'
import close from './IMG/close.svg'
import './MiniPLL.css'

const ImgPLL = props => {
    
    const [modalIsOpen, setModalIsOpen] = useState(false)
    return (
        <div >
           <img src={(props.eclarage === "DF") ? MiniPLLD : MiniPLL} alt="Mini PLL" id="IMGPLL" onClick={() => setModalIsOpen(true)}/>
            <Modal isOpen={modalIsOpen} onRequestClose={() =>setModalIsOpen(false)} style={{ overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(80, 80, 80, 0.75)'
                 },
                 content: {
                     
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    border: '1px solid #ccc',
                    background: '#FFFFFF',
                    overflow: 'auto',
                //    WebkitOverflowScrolling: 'touch',
                //    outline: 'none',
                    padding: '20px'
                 }
                 }}>


                     <img src={close} style={{display:"block", float:"right", borderRadius:"4px" }} onClick={() => setModalIsOpen(false)}/>
                     <img src={(props.eclarage === "DF") ? MiniPLLDB : MiniPLLB} alt="Mini PLL" /> 
                
               
                
            </Modal>
             
        </div>
    )
}

export default ImgPLL
