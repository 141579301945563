import React from 'react'
import { Transition } from 'react-transition-group'
import './Logo.css'

const Logo = () => {
    return (
        <div>
            <div id="firstline">
                <div ID="logo">
                    
                    <Transition timeout={3000} in={true} appear>
                       {(status) => (
                           <>
                           <a  href="/" ID="logolink" className={`box box-${status}`}>ESP</a>
                       
                           
                           </>
                        )}
                    </Transition>
                  
                </div>
                <div>
                    <h1 id="firstlinep"><span>FABRICANT / GROSSISTE</span><span> POUR LES PROFESSIONNELS</span><span> DE L’ENSEIGNE</span></h1>
                </div>
                <a id="firstlinea" href="/">Accueil</a>
            
            </div>

            
        </div>    
    )
}

export default Logo
