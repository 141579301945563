import React from 'react'

const Quantity = params => {

    const focusin = e => {
        e.target.style.background="#CCCCCC"
    }

    const Focusout = e => {
        e.target.style.background=""
    }
    return (
        <div id="quantitydiv">
                <label htmlFor="quantity" >Quantité : </label>
                <input type="number" id="quantity" min="0" placeholder="1" onFocus={focusin} onBlur={Focusout} onChange={(e) => params.setQuantity(e.target.value)} />
                
        </div>
    )
}

export default Quantity
