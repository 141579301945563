import React, {useState} from 'react'


const TempLED = props => {
   
    const [color, setColor] = useState("#757575")
    const [border, setBorder] = useState("1px solid #757575")
    const [font6500, setFont6500] = useState("unset")
    const [font4000, setFont4000] = useState("unset")
    const [font3000, setFont3000] = useState("unset")

    const focusin = () => {
        setColor("#000000")
        setBorder("2px solid #000000")
    }

    const Focusout = () => {
        setColor("#757575")
        setBorder("1px solid #757575")
    }

    const change6500 = (e) => {
        if (e.target.checked) {
            setFont6500("bold")
            setFont4000("unset")
            setFont3000("unset")
        }else {
            setFont6500("unset")
        }
    }

    const change4000 = (e) => {
        if (e.target.checked) {
            setFont6500("unset")
            setFont4000("bold")
            setFont3000("unset")
        }else {
            setFont4000("unset")
        }
    }

    const change3000 = (e) => {
        if (e.target.checked) {
            setFont6500("unset")
            setFont4000("unset")
            setFont3000("bold")
        }else {
            setFont3000("unset")
        }
    }
    
    return (
        <>
        <fieldset id="Templed" style={{border:border}}>
            <legend style={{color:color}} className="legend" >Temperature de LED :</legend>

            <input type="radio" id="6500k" name="TempLED" value="6500" onClick={(e) => props.setTempLED(e.target.value)} onChange={change6500} onFocus={focusin} onBlur={Focusout}/>
            <label htmlFor="6500k" className="radio" style={{fontWeight:font6500}}>6500k (Blanc froid)</label><br/>

            <input type="radio" id="4000" name="TempLED" value="4000" onClick={(e) => props.setTempLED(e.target.value)} onChange={change4000} onFocus={focusin} onBlur={Focusout}/>
            <label htmlFor="4000" className="radio" style={{fontWeight:font4000}}>4000k (Blanc chaud)</label><br/>

            <input type="radio" id="3000" name="TempLED" value="3000" onClick={(e) => props.setTempLED(e.target.value)} onChange={change3000} onFocus={focusin} onBlur={Focusout}/>
            <label htmlFor="6500" className="radio" style={{fontWeight:font3000}}>3000k (Blanc chaud)</label>

        </fieldset>
            
        </>
    )
}

export default TempLED
