import React from 'react';
import Modal from 'react-modal'
import './App.css'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import Logo from './main/Logo'
import Clock from './main/Clock'
import Menu from './main/Menu'
import Accueil from './main/Accueil'
import MiniPLL from './components/minipll/Minipll'
import Totem from './components/totem/Totem'
import Caisson from './components/caisson/Caisson'
import ErrorPage from './main/ErrorPage'
import FooterCopyRight from './main/FooterCopyRight'
import Footer from './main/Footer';

Modal.setAppElement('#root')

function App() {
  return (
    <>
      <Logo/>
      <Clock/>
      <BrowserRouter>
        <Menu/>
        <Switch>
          <Route exact path="/" component={Accueil}/>
          <Route strict path="/Minipll" component={MiniPLL}/>
          <Route strict path="/Caisson" component={Caisson}/>
          <Route strict path="/Totem" component={Totem}/>
          <Route component={ErrorPage}/>
        </Switch>
      </BrowserRouter>
      <Footer/>
      <FooterCopyRight/>
    </>
  );
}

export default App;
