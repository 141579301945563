import React from 'react'

const focusin = e => {
    e.target.style.background="#CCCCCC"
}

const Focusout = e => {
    e.target.style.background=""
}
const Longuer = params => {
    return (
        <div id="loguerplldiv">
            <label style={{marginRight:"5px"}} htmlFor="longuerpll">Longueur de PLL : </label>
            <input type="number" id="longuerpll" min="0" placeholder="1000" onFocus={focusin} onBlur={Focusout} onChange={(e) => params.setLonguer(e.target.value)}></input>
            <p id="longuerpllp">mm</p>
        </div>
    )
}

export default Longuer
