import React from 'react'

const ColorRAL = params => {

    const focusin = e => {
        e.target.style.background="#CCCCCC"
    }

    const Focusout = e => {
        e.target.style.background=""
    }

    return (
        <div id="colorRALDIV">
            <label htmlFor="colorRAL">Couleur RAL: </label>
            <input type="text" id="colorRAL" onChange={(e) => params.setColorRAL(e.target.value)} onFocus={focusin} onBlur={Focusout}/>
        </div>
    )
}

export default ColorRAL