import React, {useState} from 'react'

const Finition = props => {

    const [color, setColor] = useState("#757575")
    const [border, setBorder] = useState("1px solid #757575")
    const [fontANS, setFontANS] = useState("unset")
    const [font9010, setFont9010] = useState("unset")
    const [font9005, setFont9005] = useState("unset")
    const [fontRAL, setFontRAL] = useState("unset")

    const focusin = () => {
        setColor("#000000")
        setBorder("2px solid #000000")
    }

    const Focusout = () => {
        setColor("#757575")
        setBorder("1px solid #757575")
    }

    const changeANS = (e) =>{
        if (e.target.checked) {
            setFontANS("bold")
            setFont9010("unset")
            setFont9005("unset")
            setFontRAL("unset")
        }else {
            setFontANS("unset")
        }
    }

    const change9010 = (e) =>{
        if (e.target.checked) {
            setFontANS("unset")
            setFont9010("bold")
            setFont9005("unset")
            setFontRAL("unset")
        }else {
            setFont9010("unset")
        }
    }

    const change9005 = (e) =>{
        if (e.target.checked) {
            setFontANS("unset")
            setFont9010("unset")
            setFont9005("bold")
            setFontRAL("unset")
        }else {
            setFont9005("unset")
        }
    }

    const changeRAL = (e) =>{
        if (e.target.checked) {
            setFontANS("unset")
            setFont9010("unset")
            setFont9005("unset")
            setFontRAL("bold")
        }else {
            setFontRAL("unset")
        }
    }

    return (
        <>
          <fieldset id="Finition" style={{border:border}}>
            <legend style={{color:color}} className="legend">Finition :</legend>

            <input type="radio" id="ANS" name="Finition" value="ANS" onClick={(e) => props.setFinition(e.target.value)} onChange={changeANS} onFocus={focusin} onBlur={Focusout}/>
            <label htmlFor="ANS" className="radio" style={{fontWeight:fontANS}} >Anodise</label><br/>

            <input type="radio" id="LB" name="Finition" value="LB9010" onClick={(e) => props.setFinition(e.target.value)} onChange={change9010} onFocus={focusin} onBlur={Focusout}/>
            <label htmlFor="LB" className="radio" style={{fontWeight:font9010}} >Laque Blanc 9010</label><br/>

            <input type="radio" id="LN" name="Finition" value="LN9005" onClick={(e) => props.setFinition(e.target.value)} onChange={change9005} onFocus={focusin} onBlur={Focusout}/>
            <label htmlFor="LN" className="radio" style={{fontWeight:font9005}}>Laque Noir 9005</label><br/>

            <input type="radio" id="LR" name="Finition" value="LRAL" onClick={(e) => props.setFinition(e.target.value)} onChange={changeRAL} onFocus={focusin} onBlur={Focusout}/>
            <label htmlFor="LR" className="radio" style={{fontWeight:fontRAL}}>Laque RAL</label><br/>
        </fieldset>  
        </>
        
    )
}

export default Finition
