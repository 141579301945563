import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'
import './Menu.css'
import open_white from './IMG/menu_open-white-48dp.svg'
import close_black from './IMG/menu-black-48dp.svg'

const Menu = () => {

    const [open, setOpen] = useState(false)

    const menuOpen = () => {
        setOpen(!open)
    }
    return (
        <nav>
            <ul style={{transform: open ? "translateX(0px)" : "" }}>
                <li>
                    <NavLink to="/MiniPLL" onClick={() => setOpen(false)}>Mini PLL</NavLink>
                </li>
                <li>
                    <NavLink to="/Totem" onClick={() => setOpen(false)}>Totem</NavLink>
                </li>
                <li>
                    <NavLink to="/Caisson" onClick={() => setOpen(false)}>Caisson</NavLink>
                </li>
                
            </ul>
            {open ? <img src={open_white} alt="fermer" id="close" onClick={menuOpen}/> :
            <img src={close_black} alt="ouvrir" id="menu" onClick={menuOpen}/>}
            
            
        </nav>
    )
}

export default Menu
