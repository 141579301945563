import React from 'react'

const Footer = () => {
    return (
        <div id="footerdiv">
            <div id="footerabout">
                Qui sommes nous ?
            </div>
            <div id="footersocial">
                Conditions Générales de Vente
            </div>
            <div id="footercontact">
                cotact
            </div>
        </div>
    )
}

export default Footer
