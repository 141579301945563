import React, { useState, useEffect } from 'react'
import Data from './miniplldata.json'
import {Link} from 'react-router-dom'

const Table = ({quantite, longuer, tempLED, finition, colorral, faces, lPotence, typep}) => {
   
    const [codeArticle, setCodeArticle] = useState()
    const [description, setDescription] = useState()
    const [qtefab, setQtefab] = useState()
    const [qtefac, setQtefac] = useState()
    const [punite, setPunite] = useState()
    const [ptotal, setPtotal] = useState()
    const [descriptionp, setDescriptionp] = useState()
    const [finitiondescription, setFinitiondescription] = useState()    
    const [codearticlepotence, setCodeartclepotence] = useState()
    const [punitep, setPunitep] = useState()
    const [tableaffiche, setTableaffiche] =useState(false)

    let punitei = parseInt(punite)
    let codeP = ""

    const calculcodearticle = () => {

        switch (faces) {
            case "SF" :
                switch (finition) {

                    case "ANS" :
                        setCodeArticle("PLL25-ANS")
                        const PLL25ANS = Data.map(post =>{
                            return    post.PLL25ANS
                            
                        })
                        setPunite(PLL25ANS)
                        break

                    case "LB9010" :
                        setCodeArticle("PLL25")
                        const PLL25B= Data.map(post =>{
                            return  post.PLL25
                        })
                        setPunite(PLL25B)
                        break

                    case "LN9005" :
                        setCodeArticle("PLL25")
                        const PLL25N= Data.map(post =>{
                            return post.PLL25
                        })
                        setPunite(PLL25N)
                        break   
                    case "LRAL" :
                        setCodeArticle("PLL25-LA")
                        const PLL25LA = Data.map(post =>{
                            return post.PLL25LA
                        })
                        setPunite(PLL25LA)
                        break  
                    default : 
                        setCodeArticle("er")      
                }
                break
            case "DF" :
                switch (finition) {

                    case "ANS" :
                        setCodeArticle("PLL25D-ANS")
                        const PLL25DANS = Data.map(post =>{
                            return post.PLL25DANS
                        })
                        setPunite(PLL25DANS)
                        break
                    case "LB9010" :
                        setCodeArticle("PLL25D")
                        const PLL25DB = Data.map(post =>{
                            return post.PLL25D
                        })
                        setPunite(PLL25DB)
                        break
                    case "LN9005" :
                        setCodeArticle("PLL25D")
                        const PLL25DN = Data.map(post =>{
                            return post.PLL25D
                        })
                        setPunite(PLL25DN)
                        break   
                    case "LRAL" :
                        setCodeArticle("PLL25D-LA")
                        const PLL25DLA = Data.map(post =>{
                            return post.PLL25DLA
                            
                        })
                        setPunite(PLL25DLA)
                        break  
                    default : 
                        setCodeArticle("er")      
                }
                break
            default :
            setCodeArticle("err")
        }
    }
    
    const calculdescription = () => {
        setDescription(quantite+" Mini pll LED IP66-14,4W/m-1800 Lm/m")
    }

    const calculqtefab = () => {
        setQtefab(quantite)
    }

    const calculqtefac = () => {
        setQtefac(quantite*longuer/1000)

    }

    const calculdescriptionp = () => {
        if (typep === "SansP") {
            setDescriptionp("Sans Potence")
            setCodeartclepotence("")
            codeP=""
        } else {
                switch (typep) {
                    case "NDesign" :
                        setDescriptionp("Potences New Design")
                        setCodeartclepotence("PND25")
                        codeP="PND25"
                        break
                    case "NDesignP" :
                        setDescriptionp("Potences New Design pour panneau")
                        setCodeartclepotence("PNDP25")
                        codeP="PNDP25"
                        break 
                    default :
                        setDescriptionp("er")          
                }
                switch (lPotence) {
                    case "P30" :
                        setDescriptionp(prevState => prevState+" 30 mm")
                        setCodeartclepotence(prevState =>prevState+"-30")
                        codeP+="-30"
                        break
                    case "P125" :
                        setDescriptionp(prevState => prevState+" 125 mm")
                        setCodeartclepotence(prevState =>prevState+"-125")
                        codeP+="-125"
                        break
                    case "P200" :
                        setDescriptionp(prevState => prevState+" 200 mm")
                        setCodeartclepotence(prevState =>prevState+"-200")
                        codeP+="-200"
                        break
                    case "P400" :
                        setDescriptionp(prevState => prevState+" 400 mm")
                        setCodeartclepotence(prevState =>prevState+"-400")
                        codeP+="-400"
                        break
                    default :
                    setDescriptionp("er")    
                }       
                switch (finition) {
                    case "ANS" :
                        setDescriptionp(prevState => prevState+ " Laque RAL 9006 MAT")
                        setCodeartclepotence(prevState =>prevState+"ANS")
                        codeP+="ANS"
                        break
                    case "LB9010" :
                        setDescriptionp(prevState => prevState+" Laque Blanc")
                        setCodeartclepotence(prevState =>prevState+"ANS")
                        codeP+="ANS"
                        break
                    case "LN9005" :
                        setDescriptionp(prevState => prevState+" Laque Noir")
                        setCodeartclepotence(prevState =>prevState+"ANS")
                        codeP+="ANS"
                        break  
                    case "LRAL" :
                        setDescriptionp(prevState => prevState+" Laque RAL")
                        setCodeartclepotence(prevState =>prevState+"LA")
                        codeP+="LA"
                        break  
                    default :
                        setDescriptionp("er")        
                }  
        }  
    }
    
    const calculeprixunitepotence = () => {
        switch (codeP) { 
            case "PND25-30ANS" :
                setPunitep(Data.map(post => {return post.PND2530ANS} ))
                break
            case "PND25-30LA" :
                setPunitep(Data.map(post => {return post.PND2530LA} ))
                break
            case "PND25-125ANS" :
                setPunitep(Data.map(post => {return post.PND25125ANS} ))
                break
            case "PND25-125LA" : 
                setPunitep(Data.map(post => {return post.PND25125LA} ))
                break
            case "PND25-200ANS" : 
                setPunitep(Data.map(post => {return post.PND25200ANS} ))
                break
            case "PND25-200LA" : 
                setPunitep(Data.map(post => {return post.PND25200LA} ))
                break
            case "PND25-400ANS" : 
                setPunitep(Data.map(post => {return post.PND25400ANS} ))
                break
            case "PND25-400LA" : 
                setPunitep(Data.map(post => {return post.PND25400LA} ))
                break
            case  "PNDP25-125ANS" :
                setPunitep(Data.map(post => {return post.PNDP25125ANS} ))
                break 
            case  "PNDP25-125LA" :
                setPunitep(Data.map(post => {return post.PNDP25125LA} ))
                break 
            case  "PNDP25-200ANS" :
                setPunitep(Data.map(post => {return post.PNDP25200ANS} ))
                break 
            case  "PNDP25-200LA" :
                setPunitep(Data.map(post => {return post.PNDP25200LA} ))
                break     

            default :  
                alert("er calcule unite potence")   
        }
    }

    const calculefinitiondescription = () => {
        switch (finition) {
            case "ANS" : 
                setFinitiondescription("Finition : Anodisé")
                break
            case "LB9010" :
                setFinitiondescription("Finition : Laque Blanc 9010")
                break
            case "LN9005" :
                setFinitiondescription("Finition : Laque Noir 9005")
                break
            case "LRAL" :
                setFinitiondescription("Finition : Laque RAL")
                break
            default :
                setFinitiondescription("erv")            
        }
            
    }   

    const controlchamp = () =>{
    //    if (faces !== "SF") {alert("yes")}
        if (quantite<1 || quantite === undefined) {alert("Merci de renseigner la quantité")}
        else if(longuer<200 || longuer === undefined) {alert("Merci de renseigner une longueur supérieure à 200 mm")}
        else if (faces === undefined) {alert("Merci de choisir le type d'éclairage")}
        else if (typep === undefined) {alert("Merci de renseigner le type potence")}
        else if (lPotence === undefined && (typep === "NDesign" || typep === "NDesignP")) {alert("Merci de choisir la longueur de potence")}
        else if (tempLED === undefined) {alert ("Merci de renseigner la temperature de LED")}
        else if (finition === undefined) {alert("Merci de renseigner la finition")}
        else if (colorral === undefined && finition === "LRAL") {alert("Merci de renseigner la couler de RAL")}
        else {return true}
    }    

    const calcul = () =>{
        if (controlchamp()) {
            setTableaffiche(true)
            calculcodearticle()
            calculdescription()
            calculqtefab()
            calculqtefac()
            calculefinitiondescription()
            calculdescriptionp()
            calculeprixunitepotence()
        } 
           
    }
    
    useEffect(() => {
        setTableaffiche(false)
    },[quantite,longuer,tempLED,finition,colorral, faces, lPotence, typep])
 
    function tableajouteligne  (codeArticlet,descriptiont,qtefabt,qtefact,punitet,ptotalt)  {
        return(
            <tr>
                <td>{codeArticlet}</td>
                <td>{descriptiont}</td>
                <td>{qtefabt}</td>
                <td>{qtefact}</td>
                <td>{punitet}</td>
                <td>{ptotalt}</td>
            </tr>
        )
    }
   
    return (
        <div >
            <button id="Btncalculdevis" onClick={calcul}>CALCULER LE DEVIS</button> 
            <table id="table">
                <tr>
                    <th >code article</th>
                    <th>Description</th>
                    <th>Qte fab</th>
                    <th>Qte fac</th> 
                    <th>P.U</th>
                    <th>Prix total</th>
                </tr>
                
                { tableaffiche && tableajouteligne(codeArticle,description,qtefab,qtefac,punitei,punitei*qtefac)}
                { tableaffiche && tableajouteligne("","Longueur : "+longuer+" mm","","","","")} 
                { tableaffiche && tableajouteligne("","Temperature de LED  : "+tempLED+"k","","","","")} 
                { tableaffiche && tableajouteligne("",finitiondescription,"","","","")} 
                { tableaffiche && tableajouteligne(codearticlepotence,descriptionp,"","",punitep,"")} 
               
               
                
            </table>
              
            
        </div>
    )
}

export default Table
