import React, {useState, useEffect} from 'react'
import './MiniPLL.css'
import Quantity from './Quantity'
import Longuer from './Longuer'
import TempLED from './TempLED'
import Finition from './Finition'
import ColorRAL from './ColorRAL'
import Faces from './Faces'
import LPotence from './LPotence'
import TypeP from './TypeP'
import Table from './Table'



const Minipll = () => {

    useEffect(() => {
        document.title="ESP Mini PLL"
    },[])
    

    const [quantity, setQuantity] = useState()
    const [longuer, setLonguer] = useState()
    const [tempLED, setTempLED] = useState()
    const [finition, setFinition] = useState()
    const [colorRAL, setColorRAL] = useState()
    const [faces, setFaces] = useState()
    const [lPotence, setLPotence] = useState()
    const [typeP, setTypeP] = useState()

 
    return (
        <div>
            <p>Renseignez les caractéristiques</p>
            <div id="lineone">
                <Quantity  setQuantity={setQuantity}/>
                <Longuer  setLonguer={setLonguer}/>
            </div>

            <div id="linetwo">
                <Faces setFaces={setFaces}/>
                <TypeP setTypeP={setTypeP}/>
                {(typeP !=="SansP") ? <LPotence eclarage={faces} potence={typeP} setLPotence={setLPotence}/> : null} 
                
            </div>

            <div id="linethree">
               <TempLED setTempLED={setTempLED}/>
               <Finition setFinition={setFinition}/>
            </div> 
            
               {finition === "LRAL" ? <ColorRAL setColorRAL={setColorRAL}/> : null}
            

            

          {/*} <div>Q :{quantity} Longuer :{longuer} TempLED :{tempLED} Finition :{finition} LaqueRAL: {colorRAL} Faces: {faces} LPotence: {lPotence} TypeP {typeP} </div>*/}
            
    <Table quantite ={quantity} longuer ={longuer} tempLED ={tempLED} finition ={finition} colorral= {colorRAL} faces= {faces} typep ={typeP} lPotence= {lPotence} />
            
        </div>
    )
}

export default Minipll
