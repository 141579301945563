import React, {useState} from 'react'

const LPotence = props => {

    const [color, setColor] = useState("#757575")
    const [border, setBorder] = useState("1px solid #757575")
    const [font30, setFont30] = useState("unset")
    const [font125, setFont125] = useState("unset")
    const [font200, setFont200] = useState("unset")
    const [font400, setFont400] = useState("unset")
    
    

    const focusin = () => {
        setColor("#000000")
        setBorder("2px solid #000000")
    }

    const Focusout = () => {
        setColor("#757575")
        setBorder("1px solid #757575")
    }

    const change30 = (e) =>{
        if (e.target.checked) {
            setFont30("bold")
            setFont125("unset")
            setFont200("unset")
            setFont400("unset")
        }else {
            setFont30("unset")
        }
    } 

    const change125 = (e) =>{
        if (e.target.checked) {
            setFont30("unset")
            setFont125("bold")
            setFont200("unset")
            setFont400("unset")
        }else {
            setFont125("unset")
        }
    }

    const change200 = (e) =>{
        if (e.target.checked) {
            setFont30("unset")
            setFont125("unset")
            setFont200("bold")
            setFont400("unset")
        }else {
            setFont200("unset")
        }
    }

    const change400 = (e) =>{
        if (e.target.checked) {
            setFont30("unset")
            setFont125("unset")
            setFont200("unset")
            setFont400("bold")
        }else {
            setFont400("unset")
        }
    }


    return (
        <div>
            <fieldset id="LPotences" style={{border:border}} >
                <legend style={{color:color}} className="legend">L Potence :</legend>
                {(props.eclarage === "SF" && props.potence === "NDesign" ) ? (<><input type="radio" id="potence30" name="potences" value="P30" onClick={(e) => props.setLPotence(e.target.value)} onChange={change30} onFocus={focusin} onBlur={Focusout}/>
                <label htmlFor="potence30" className="radio" style={{fontWeight:font30}}>30 mm</label> <br/></>) : null }

                <input type="radio" id="potence125" name="potences" value="P125" onClick={(e) => props.setLPotence(e.target.value)} onChange={change125} onFocus={focusin} onBlur={Focusout}/>
                <label htmlFor="potence125" className="radio" style={{fontWeight:font125}}>125 mm</label> <br/>

                <input type="radio" id="potence200" name="potences" value="P200" onClick={(e) => props.setLPotence(e.target.value)} onChange={change200} onFocus={focusin} onBlur={Focusout}/>
                <label htmlFor="potence200" className="radio" style={{fontWeight:font200}}>200 mm</label> <br/>

                {(props.eclarage === "SF" && props.potence === "NDesign") ? (<><input type="radio" id="potence400" name="potences" value="P400" onClick={(e) => props.setLPotence(e.target.value)} onChange={change400} onFocus={focusin} onBlur={Focusout}/>
                <label htmlFor="potence400" className="radio" style={{fontWeight:font400}}>400 mm</label><br/></>) : null}
                
            </fieldset>
            
        </div>
    )
}

export default LPotence
