import React, {useState} from 'react'

const TypeP = props => {

    const [color, setColor] = useState("#757575")
    const [border, setBorder] = useState("1px solid #757575")
    const [fontD, setFontD] = useState("unset")
    const [fontP, setFontP] = useState("unset")
    const [fontS, setFontS] = useState("unset")
    

    const focusin = () => {
        setColor("#000000")
        setBorder("2px solid #000000")
    }

    const Focusout = () => {
        setColor("#757575")
        setBorder("1px solid #757575")
    }

    const changeD = (e) => {
        if (e.target.checked) {
            setFontD("bold")
            setFontP("unset")
            setFontS("unset")
        }else {
            setFontD("unset")
        }
    }

    const changeP = (e) => {
        if (e.target.checked) {
            setFontD("unset")
            setFontP("bold")
            setFontS("unset")
        }else {
            setFontP("unset")
        }
    }

    const changeS = (e) => {
        if (e.target.checked) {
            setFontD("unset")
            setFontP("unset")
            setFontS("bold")
        }else {
            setFontS("unset")
        }
    }

    return (
        <div >
            <fieldset id="TypePfieldset" style={{border:border}}> 
            <legend style={{color:color}} className="legend">Type de potence :</legend>

            <input type="radio" id="NDesign" name="TPotence" value="NDesign" onClick={(e) => props.setTypeP(e.target.value)} onChange={changeD} onFocus={focusin} onBlur={Focusout}/>
            <label htmlFor="NDesign" className="radio" style={{fontWeight:fontD}}>New Design</label><br/>

            <input type="radio" id="NDesignP" name="TPotence" value="NDesignP" onClick={(e) => props.setTypeP(e.target.value)} onChange={changeP} onFocus={focusin} onBlur={Focusout}/>
            <label htmlFor="NDesignP" className="radio" style={{fontWeight:fontP}}>Pour panneau</label><br/>

            <input type="radio" id="sans" name="TPotence" value="SansP" onClick={(e) => props.setTypeP(e.target.value)} onChange={changeS} onFocus={focusin} onBlur={Focusout}/>
            <label htmlFor="sans" className="radio" style={{fontWeight:fontS}}>Sans Potence</label>
            </fieldset>
        </div>
    )
}

export default TypeP
