import React from 'react'
import './footer.css'

const FooterCopyRight = () => {
    return (
        <div id="footercopyrightdiv">
            <p id="p1">Copyright © 2020 - Tous droit réservés</p>
            <p id="p2">Designed & developed by Reza - 0687007653</p>
            <p id="p1r">© 2020</p>
            <p id="p2r">Designed & developed by Reza</p>
        </div>
    )
}

export default FooterCopyRight
